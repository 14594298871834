import React, { useEffect, useRef, useState } from 'react';
import './AutoType.scss';

let settimeout1, settimeout2, settimeout3, settimeout4, settimeout5;

const AutoType = ({ actions, speed = 200, style }) => {
  const [textArr, setTextArr] = useState([]);
  const indexRef = useRef(0);

  useEffect(() => {
    setTimeout(() => {
      reset();
      handle();
    }, 0);
    return reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const reset = () => {
    settimeout1 && clearTimeout(settimeout1);
    settimeout2 && clearTimeout(settimeout2);
    settimeout3 && clearTimeout(settimeout3);
    settimeout4 && clearTimeout(settimeout4);
    settimeout5 && clearTimeout(settimeout5);

    indexRef.current = 0;
    setTextArr(() => []);
  };

  const handle = () => {
    const current = actions[indexRef.current];
    if (!current) return;
    switch (current?.type) {
      case 'text':
        handleText(current);
        break;
      case 'wait':
        handleWait(current);
        break;
      case 'br':
        handleBr(current);
        break;
      case 'clean':
        handleClean(current);
        break;
      case 'repeat':
        handleRepeat(current);
        break;
      default:
        handlePlainText(current);
    }
  };

  const handleType = (text, index, time) => {
    if (index > 0 && !settimeout1) {
      next();
      return;
    }
    if (index < text.length) {
      setTextArr(prev => {
        return [...prev, text[index]];
      });
      settimeout1 = setTimeout(handleType, time, text, ++index, time);
    } else {
      next();
    }
  };

  const handleText = type => {
    const text = type.text?.split('') || [];
    handleType(text, 0, type.time || speed);
  };

  const handleWait = type => {
    settimeout2 = setTimeout(next, type.time || speed);
  };

  const handleClean = () => {
    setTextArr(() => []);
    next();
  };

  const handleBr = type => {
    setTextArr(prev => {
      return [...prev, '<br />'];
    });
    settimeout3 = setTimeout(next, type.time || speed);
  };

  const handleRepeat = type => {
    settimeout4 = setTimeout(() => {
      indexRef.current = 0;
      setTextArr(() => []);
      handle();
    }, type.time || speed);
  };

  const handlePlainText = type => {
    setTextArr(prev => {
      textArr.push(type);
      return [...prev];
    });
    settimeout5 = setTimeout(function () {
      next();
    }, speed);
  };

  const next = () => {
    indexRef.current++;
    handle();
  };

  return (
    <div
      className='auto-type'
      style={style}
      dangerouslySetInnerHTML={{ __html: textArr.join('') }}
    />
  );
};

export default AutoType;
