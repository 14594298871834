import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppState } from '../../wallet/state';
import { connectorsByName, ConnectorName } from '../../wallet/connector';
import { ReactComponent as ArrowRightSvg } from '../../assets/imgs/arrow-right.svg';
import { Spinner } from '../index';
import './Login.scss';

const Login = () => {
  const { activatingConnector, setActivatingConnector, resetAll } = useAppState();
  const { connector, activate, deactivate, error } = useWeb3React();
  const currectConnector = connectorsByName[ConnectorName];

  const activating = !error && currectConnector && currectConnector === activatingConnector;
  const connected = !error && connector && currectConnector === connector;

  function connect() {
    setActivatingConnector(currectConnector);
    activate(currectConnector);
    window.sessionStorage.setItem('logined', 1);
  }

  function disConnect() {
    setActivatingConnector(undefined);
    deactivate();
    resetAll();
    window.sessionStorage.removeItem('logined');
  }

  useEffect(() => {
    const logined = window.sessionStorage.getItem('logined');
    if (logined) connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {connected ? (
        <div className='wallet-btn' onClick={disConnect}>
          CONNECTED
        </div>
      ) : (
        <div className='wallet-btn' onClick={connect}>
          CONNECT WALLET
          {activating && !connected ? <Spinner /> : <ArrowRightSvg />}
        </div>
      )}
    </>
  );
};

export default Login;
