import { useEffect, useState } from 'react';
import SCC from '../../wallet/scc.json';
import { CountDown, AutoType } from '../index';
import { useAppState } from '../../wallet/state';
import './Membership.scss';

const Membership = ({ openTime }) => {
  const { membercardMintOpenTime, membercardLevels, mintMemberCard, minting } =
    useAppState();
  const { day, month, date } = membercardMintOpenTime;
  const canMint = membercardLevels.some(level => level);

  const [timeCanMint, setTimeCanMint] = useState(false);

  let membercardArr = SCC.membercard.map(card => card.name);
  const membercardMinting =
    minting !== -1 ? membercardArr[minting].split(' ') : [];
  if (membercardLevels.length > 0 && membercardLevels.some(level => level)) {
    membercardArr = SCC.membercard
      .filter((card, index) => membercardLevels[index])
      .map(card => card.name);
  }

  // generate print rules
  const printArr = membercardArr
    .map(cardStr => {
      const cardArr = cardStr.split(' ');
      const resultArr = [];
      cardArr.forEach((word, index) => {
        resultArr.push({
          type: 'text',
          text: word,
        });
        if (index !== cardArr.length - 1) {
          resultArr.push({
            type: 'br',
          });
        }
      });
      return [
        ...resultArr,
        {
          type: 'wait',
          time: '1000',
        },
        {
          type: 'clean',
        },
      ];
    })
    .flat(1);

  printArr.push({
    type: 'repeat',
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const openTime = date.getTime();
      const nowTime = new Date().getTime();

      if (openTime <= nowTime) {
        clearInterval(intervalId);
        setTimeCanMint(true);
      }
    }, 1000);

    return () => intervalId && clearInterval(intervalId);
  }, [date]);

  function handleMint() {
    if (!canMint) return;

    mintMemberCard();
  }

  return (
    <>
      {timeCanMint && minting === -1 && (
        <h2 className='start-time'>
          <span
            className={
              canMint ? 'start-text  mint-text' : 'start-text no-mint-text'
            }
            onClick={handleMint}
          >
            MINT
          </span>
          <span className='start-text'>
            <span className='hidden-text'>
              <br />
              <br />
              <br />
            </span>
            <span className='auto-text'>
              <AutoType actions={printArr} />
            </span>
          </span>
        </h2>
      )}

      {!timeCanMint && minting === -1 && (
        <h2 className='start-time'>
          <span className='start-text'>MINT</span>
          <span className='start-text'>START</span>
          <span className='start-text'>ON {day}</span>
          <span className='start-text'>{month}</span>
        </h2>
      )}

      {minting !== -1 && (
        <h2 className='start-time'>
          <span className='start-text minting'>MINTING</span>
          <span className='start-text'>{membercardMinting[0]}</span>
          <span className='start-text'>{membercardMinting[1]}</span>
          <span className='start-text'>{membercardMinting[2]}</span>
        </h2>
      )}

      <div className='count-down-container'>
        <CountDown openTime={openTime} />
      </div>
    </>
  );
};

export default Membership;
