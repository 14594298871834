import React from 'react';
import './Gachakon.scss';

function Gachakon() {
  return (
    <>
      <h2 className='coming-soon'>Coming&nbsp;Soon</h2>
    </>
  );
}

export default Gachakon;
