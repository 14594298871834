import { InjectedConnector } from '@web3-react/injected-connector';

export const POLLING_INTERVAL = 10000;

export const injected = new InjectedConnector({ supportedChainIds: [1] });

export const ConnectorName = 'Metamask';

export const connectorsByName = {
  [ConnectorName]: injected,
};
