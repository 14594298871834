import './Spinner.scss';

export default function Spinner(props) {
  const { color, ...rest } = props;
  return (
    <svg
      t='1635233449134'
      className='spinner'
      viewBox='0 0 1024 1024'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      p-id='2367'
      width='32'
      height='32'
    >
      <path
        fill={color}
        {...rest}
        d='M512 1024c-136.768 0-265.344-53.248-362.048-149.952s-149.952-225.28-149.952-362.048c0-96.832 27.168-191.136 78.592-272.704 50.016-79.296 120.672-143.36 204.352-185.312l43.008 85.824c-68.032 34.08-125.504 86.176-166.144 150.656-41.76 66.208-63.808 142.784-63.808 221.504 0 229.376 186.624 416 416 416s416-186.624 416-416c0-78.688-22.08-155.296-63.808-221.504-40.672-64.48-98.112-116.576-166.144-150.656l43.008-85.824c83.68 41.92 154.368 106.016 204.352 185.312 51.424 81.568 78.592 175.84 78.592 272.704 0 136.768-53.248 265.344-149.952 362.048s-225.28 149.952-362.048 149.952z'
        p-id='2368'
      ></path>
    </svg>
  );
}
