import SCC from './scc.json';
import create from 'zustand';
import { Contract, utils } from 'ethers';
import TREE from './treeScc2.json';

const useAppState = create((set, get) => ({
  membercardMintOpenTime: {
    date: new Date('2021/11/10,12:00:00'),
    day: 10,
    month: 'NOVERBER',
  },

  contract: undefined,
  user: undefined,
  memberCardLevelProofs: [],
  membercardLevels: [],
  minting: -1,

  activatingConnector: undefined, // metamask network & other connecting event like change network
  library: undefined, // Web3Provider

  setContract: async (library, chainId) => {
    try {
      if (!library) throw new Error('No Web3 Found');

      const deployedNetwork = SCC.networks[String(chainId)];

      if (!deployedNetwork) {
        throw new Error('The network you selected is no supported yet.');
      }

      const { address } = deployedNetwork;
      const contract = new Contract(address, SCC.abi, library.getSigner());

      const name = await contract.name();
      const symbol = await contract.symbol();

      set({
        library,
        contract,
        contractDetails: {
          name,
          symbol,
          address,
        },
      });
    } catch (e) {
      console.log(e);
    }
  },
  setUser: async address => {
    try {
      const { contract, user, library } = get();

      if (!library) throw new Error('No Web3 Found');
      if (!contract) throw new Error('No contract found');
      if (!user && !address) throw new Error('No user found');

      set({
        user: { address: address || user?.address || '' },
      });
    } catch (e) {
      console.log(e);
    }
  },
  setUserLevels: async address => {
    try {
      const { contract, library, user } = get();

      if (!library) throw new Error('No Web3 Found');
      if (!contract) throw new Error('No contract found');
      if (!user?.address && !address) throw new Error('No user found');

      const userAddress = user?.address || address;
      console.log(userAddress)
      const memberCardLevelProofs = [];
      console.log(userAddress);
      Object.keys(TREE).forEach((level, index) => {
        const originalTreeLevelArr = Object.keys(TREE[level]);
        const transferedTreeLevelArr = originalTreeLevelArr.map(addr =>
          addr === 'root' ? addr : utils.getAddress(addr)
        );

        const addressIndex = transferedTreeLevelArr.indexOf(userAddress);
        memberCardLevelProofs[index] =
          addressIndex === -1 ? null : TREE[level][originalTreeLevelArr[addressIndex]];
      });
      console.log(memberCardLevelProofs);

      // const levels = await Promise.all(
      //   memberCardLevelProofs.map(async (level, index) => {
      //     if (!level) return false;
      //     console.log(level);
      //     return await contract.verifyWhiteList(index, userAddress, level);
      //   })
      // );
      const whiteListed = await contract.isWhiteListed(userAddress);
      const levels = memberCardLevelProofs.map((item, index) => {
        return item && whiteListed[index] === false ? true : false;
      });
      console.log(levels);

      set({
        memberCardLevelProofs,
        membercardLevels: levels,
      });
    } catch (e) {
      console.log(e);
    }
  },
  setActivatingConnector: activatingConnector => set({ activatingConnector: activatingConnector }),
  mintMemberCard: async () => {
    try {
      const {
        library,
        contract,
        user,
        address,
        membercardLevels,
        getTransactionReceiptMined,
        memberCardLevelProofs,
      } = get();
      if (!library) throw new Error('No Web3 Found');
      if (!contract) throw new Error('No contract found');
      if (!user?.address && !address) throw new Error('No user found');

      const mintLevel = membercardLevels.indexOf(true);
      if (mintLevel === -1) throw new Error('Not available to mint');

      console.log(mintLevel, memberCardLevelProofs[mintLevel]);
      const result = await contract.mintWhiteList(mintLevel, memberCardLevelProofs[mintLevel]);

      set({
        minting: membercardLevels.indexOf(true),
      });

      getTransactionReceiptMined(result.hash);
    } catch (e) {
      console.log(e);
    }
  },
  getTransactionReceiptMined: async hash => {
    try {
      const { library, setUserLevels, getTransactionReceiptMined } = get();
      if (!library) throw new Error('No Web3 Found');

      const txReceipt = await library.getTransactionReceipt(hash);
      console.log(txReceipt);
      if (txReceipt) {
        set({
          minting: -1,
        });
        setUserLevels();
      } else {
        setTimeout(() => {
          getTransactionReceiptMined(hash);
        }, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  },
  resetAll: () => {
    set({
      contract: undefined,
      user: undefined,
      membercardLevels: [],
    });
  },
}));

export { useAppState };
