import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Login } from '../../components/index';
import { ReactComponent as TwitterSvg } from '../../assets/imgs/twitter.svg';
import { ReactComponent as DiscordSvg } from '../../assets/imgs/discord.svg';
import { ReactComponent as OpenseaSvg } from '../../assets/imgs/opensea.svg';
import { ReactComponent as LogoSvg } from '../../assets/imgs/logo.svg';

import './Header.scss';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const links = (
    <div className='social-container'>
      <Link
        className='social twitter'
        rel='noopener noreferrer'
        to={{ pathname: 'https://twitter.com/SccDao' }}
        target='_blank'
      >
        <TwitterSvg />
      </Link>
      <Link
        className='social discord'
        rel='noopener noreferrer'
        to={{ pathname: 'https://discord.gg/mNaSNbyUNs' }}
        target='_blank'
      >
        <DiscordSvg />
      </Link>
      <Link
        className='social opensea'
        rel='noopener noreferrer'
        to={{ pathname: 'https://opensea.io/collection/scc-card' }}
        target='_blank'
      >
        <OpenseaSvg />
      </Link>
    </div>
  );

  function toggleMenu() {
    if (showMenu) {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    }

    setShowMenu(!showMenu);
  }

  return (
    <>
      <div className='header-container'>
        <div className='header-left'>
          <div className='logo-container'>
            <LogoSvg />
          </div>
          <ul className='menu-container'>
            <li className='menu-item'>
              <NavLink to='/'>Membership</NavLink>
            </li>
            <li className='menu-item'>
              <NavLink to='/gachakon'>Gachakon</NavLink>
            </li>
          </ul>
        </div>
        <div className='header-right'>
          {links}
          <div className='wallet-container'>
            <Login />
          </div>
        </div>
        <div
          className={showMenu ? 'header-menu-toggle show' : 'header-menu-toggle'}
          onClick={toggleMenu}
        >
          <span className='hamburger'></span>
        </div>
      </div>

      {/* phone menu */}
      <div
        className={showMenu ? 'phone-menu-container' : 'phone-menu-container hidden'}
        onTouchMove={() => {}}
      >
        <div className='modal'></div>
        <div className='phone-menu-content'>
          <ul className='menu-container'>
            <li className='menu-item'>
              <NavLink to='/'>Membership</NavLink>
            </li>
            <li className='menu-item'>
              <NavLink to='/gachakon'>Gachakon</NavLink>
            </li>
          </ul>
          {links}
        </div>
      </div>
    </>
  );
};

export { Header };
