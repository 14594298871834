import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import { Header, Connect, StartInfo, Footer } from './layout';
import { Membership, Gachakon } from './components/index.jsx';

import { POLLING_INTERVAL } from './wallet/connector.jsx';

import './App.scss';

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}

const App = () => {
  return (
    <Router>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className='scc'>
          {/* header */}
          <header className='scc-header'>
            <Header />
          </header>

          <Connect />

          {/* main body */}
          <div className='scc-main'>
            <StartInfo>
              <Switch>
                <Route exact path='/'>
                  <Membership />
                </Route>
                <Route path='/gachakon'>
                  <Gachakon />
                </Route>
              </Switch>
            </StartInfo>
          </div>

          {/* footer */}
          <footer className='scc-footer'>
            <Footer />
          </footer>
        </div>
      </Web3ReactProvider>
    </Router>
  );
};

export default App;
