import { ReactComponent as LogoBottomSvg } from '../../assets/imgs/logo-bottom.svg';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content'>
        <div className='line line1'></div>
        <LogoBottomSvg />
        <div className='line line2'></div>
      </div>

      <div className='footer-menu'>
        <li className='menu-item'>
          About SCC
          <div className='popover-container'>
            <p className='popover-content'>
              We are Secret, Super, Smart. We are Creators, Collectors. We are
              Club. Three in two, two in one. All in SCC. We are the one, We are
              SCC.
            </p>
          </div>
        </li>
        <li className='menu-item'>
          About SCC DAO
          <div className='popover-container popover-container-2'>
            <p className='popover-content'>
              The Dao is the underlying principle behind the creation of the
              myriad things. The order of the process giving rise to the myriad
              things began with the Dao producing a kind of generative force.
              One come two, two come three, three come all things. We are SCC
              DAO.
            </p>
          </div>
        </li>
      </div>
    </div>
  );
};

export { Footer };
