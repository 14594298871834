import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from './connector';

export function useEagerConnect() {
  const { activate, active, connector } = useWeb3React();

  const [tried, setTried] = useState(false);

  // intentionally only running on mount (make sure it's only mounted once
  useEffect(() => {
    if (connector === injected) {
      injected.isAuthorized().then(isAuthorized => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      });
    }
  }, [activate, connector]);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress = false) {
  const { active, error } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum) ethereum.autoRefreshOnNetworkChange = true;
  }, [active, error, suppress]);
}
