import { useState, useEffect } from 'react';
import { useAppState } from '../../wallet/state';
import './CountDown.scss';

const CountDown = () => {
  const { membercardMintOpenTime } = useAppState();
  const [leftTime, setLeftTime] = useState({
    d: '00',
    h: '00',
    m: '00',
    s: '00',
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const openTime = membercardMintOpenTime.date.getTime();
      const nowTime = new Date().getTime();

      let leftTime = parseInt((openTime - nowTime) / 1000);
      if (leftTime < 0) leftTime = 0;

      setLeftTime({
        d: parseInt(leftTime / (24 * 60 * 60))
          .toString()
          .padStart(2, '0'),
        h: parseInt((leftTime / (60 * 60)) % 24)
          .toString()
          .padStart(2, '0'),
        m: parseInt((leftTime / 60) % 60)
          .toString()
          .padStart(2, '0'),
        s: parseInt(leftTime % 60)
          .toString()
          .padStart(2, '0'),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [membercardMintOpenTime]);

  const { d, h, m, s } = leftTime;

  return (
    <section className='count-down'>
      <span className='time-text'>{d}</span>
      <span className='slash'>/</span>
      <span className='time-text'>{h}</span>
      <span className='slash'>/</span>
      <span className='time-text'>{m}</span>
      <span className='slash'>/</span>
      <span className='time-text'>{s}</span>
    </section>
  );
};

export default CountDown;
