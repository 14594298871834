import { useEffect, useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError } from '@web3-react/injected-connector';
// import useSWR from 'swr'
import { useEagerConnect, useInactiveListener } from '../../wallet/hooks';
import { useAppState } from '../../wallet/state';

import './Connect.scss';

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop.';
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're in an unsupported network. Please connect to Ethereum network.";
  } else if (error instanceof UserRejectedRequestError) {
    return 'Please authorize this website to access your Ethereum account.';
  } else {
    return 'An unknown error occurred. Check the console for more details.';
  }
}

const Connect = () => {
  const { activatingConnector } = useAppState();
  const { library, chainId, account, error } = useWeb3React();
  // const {} =

  const { setContract, setUser, setUserLevels, membercardLevels, resetAll, minting } = useAppState(
    useCallback(
      ({ setContract, setUser, setUserLevels, membercardLevels, resetAll, minting }) => ({
        setContract,
        setUser,
        setUserLevels,
        membercardLevels,
        resetAll,
        minting
      }),
      []
    )
  );

  // useSWR(ETHSCAN_API, fetcherETHUSD)

  useEffect(() => {
    if (!chainId || !account || !library || error) {
      resetAll();
      return;
    }

    const update = async () => {
      try {
        await setContract(library, chainId);
        setUser(account);
        await setUserLevels(account);
      } catch (e) {
        console.log(e);
      }
    };

    update();
  }, [chainId, account, library, error, setContract, setUser, setUserLevels, resetAll, minting]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <>
      {error && <div className='info-container'>{getErrorMessage(error)}</div>}
      {membercardLevels.length > 0 && membercardLevels.every(level => !level) && (
        <div className='info-container'>Sorry, You are not in the whiteList</div>
      )}
      {minting !== -1 && <div className='info-container'>Minting... Please wait for a while</div>}
    </>
  );
};

export { Connect };
