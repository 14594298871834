import { Component } from 'react';
import './MemberCard.scss';
import SccBlue from '../../assets/imgs/scc-blue.jpg';
import SccBlack from '../../assets/imgs/scc-black.png';
import SccGold from '../../assets/imgs/scc-gold.png';
import SccRed from '../../assets/imgs/scc-red.png';

class MemberCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      curMemberCardIndex: 0,
      memberCardConfig: [
        {
          src: SccBlue,
          name: 'Solid Collector Card',
        },
        {
          src: SccBlack,
          name: 'Secret Collector Card',
        },
        {
          src: SccGold,
          name: 'Super Collector Card',
        },
        {
          src: SccRed,
          name: 'Smart Collector Card',
        },
      ],
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      let index = this.state.curMemberCardIndex;
      this.setState({
        curMemberCardIndex: index === 3 ? 0 : ++index,
      });
      console.log(this.state.curMemberCardIndex);
    }, 5000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId);
  }

  render() {
    const { memberCardConfig, curMemberCardIndex } = this.state;
    const { src, name } = memberCardConfig[curMemberCardIndex];

    return (
      <div className='member-card-container'>
        <div className='card-container'>
          <img src={src} alt={name} />
          <p className='card-name'>{name}</p>
        </div>
        <img className='hidden' src={SccRed} alt='memberCard4' />
      </div>
    );
  }
}

export default MemberCard;
