import { useState } from 'react';
import sccBg from '../../assets/imgs/scc-bg.png';
import './StartInfo.scss';

const StartInfo = ({ children }) => {
  const [showContent, setshowContent] = useState(false);

  function handleImageLoaded() {
    setshowContent(true);
  }

  return (
    <div className={showContent ? 'start-info-container' : 'start-info-container hide'}>
      <div className='img-container'>
        <img className='scc-img-bg' src={sccBg} onLoad={handleImageLoaded} alt='scc-icon-bg' />
        {children}
      </div>
    </div>
  );
};

export { StartInfo };
